import { Container, ContainerProps, Grid, GridProps } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { StyledFlexibleContentRow } from '../flexibleContent'

export type ContainedProps = ContainerProps & {
  container?: boolean
  itemProps?: Omit<GridProps, 'item'>
  containerProps?: Omit<GridProps, 'container'>
} & Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'justifyContent'>

const StyledContainer = styled(Container)`
  ${StyledFlexibleContentRow} {
    padding: 0;
  }
`

export const Contained: React.FC<PropsWithChildren<ContainedProps>> = ({ xs = true, sm, md = 10, lg, xl, justifyContent = 'center', container, itemProps, containerProps, children, ...props }) => {
  containerProps = { justifyContent, ...containerProps }
  itemProps = { xs, sm, md, lg, xl, ...itemProps }

  const inner = (
    <Grid container { ...containerProps }>
      <Grid item { ...itemProps }>
        {children}
      </Grid>
    </Grid>
  )

  return container ? (
    <StyledContainer { ...props }>
      {inner}
    </StyledContainer>
  ) : inner
}